import { initializeApp } from 'firebase/app';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyCrG9w26olwviaFg6TYNvz_pRYmaiLputw",
    authDomain: "evont-26ddb.firebaseapp.com",
    projectId: "evont-26ddb",
    storageBucket: "evont-26ddb.appspot.com",
    messagingSenderId: "1032701078793",
    appId: "1:1032701078793:web:e74ac9659a2e83e91637d8",
    measurementId: "G-VTJV7DW5ZY"
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const firestore = getFirestore(app);

export { storage, firestore, getDownloadURL, ref };