<template>
  <div class="about">
    <EvontNavbar location="/about-us"></EvontNavbar>
    <!-- <img alt="Vue logo" src="../assets/EvontFull.png" /> -->
    <div class="desktopOnly">
      <el-menu
        :default-active="sidemenuIndex"
        class="el-menu-vertical-demo"
        @select="handleOpen"
      >
        <img
          @click="goToHome()"
          class="navlogo"
          src="./../assets/Evont-4.svg"
          alt="Evont logo"
        />
        <el-menu-item index="0">
          <template #title> FAQ </template>
        </el-menu-item>
        <!-- <el-menu-item index="1">
          <template #title> Policies </template>
        </el-menu-item>
        <el-menu-item index="2">
          <template #title> Contacts </template>
        </el-menu-item> -->
      </el-menu>
    </div>

    <div class="aboutContent">
      <div v-if="sidemenuIndex == '0'">
        <el-collapse v-model="collapseIndex" accordion>
          <el-collapse-item name="0">
            <template #title>
              <div class="alignleft">{{ $t('message.who_are_we') }}</div>
            </template>
            <div>
              {{ $t('message.who_are_we2') }}
            </div>
          </el-collapse-item>
          <el-collapse-item name="1">
            <template #title>
              <div class="alignleft">{{ $t('message.where_are_we') }}</div>
            </template>
            <div>
              {{ $t('message.where_are_we2') }}
            </div>
            <div>
              <div style="width: 100%">
                <iframe
                  class="googlemaps"
                  frameborder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                  src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Rua%20do%20Dr%20Filomeno%20Da%20Câmara%2032&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                ></iframe>
                <span style="padding: 10px;"></span>
                <img src="./../assets/FotoEntrada.png" class="imgmaps">
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item name="2">
            <template #title>
              <div class="alignleft">{{ $t('message.where_do_i_register') }}</div>
            </template>
            <div v-html="$t('message.where_do_i_register2')"/>
          </el-collapse-item>
          <el-collapse-item name="3">
            <template #title>
              <div class="alignleft">{{ $t('message.what_games_are_available') }}</div>
            </template>
            <div>
              {{ $t('message.what_games_are_available2') }}
            </div>
          </el-collapse-item>
          <el-collapse-item name="4">
            <template #title>
              <div class="alignleft">{{ $t('message.are_there_prizes') }}</div>
            </template>
            <div>
              {{ $t('message.are_there_prizes2') }}
            </div>
          </el-collapse-item>
          <el-collapse-item name="5">
            <template #title>
              <div class="alignleft">
                {{ $t('message.i_forgot_to_register') }}
              </div>
            </template>
            <div>
              {{ $t('message.i_forgot_to_register2') }}
            </div>
          </el-collapse-item>
          <el-collapse-item name="6">
            <template #title>
              <div class="alignleft">
                {{ $t('message.what_about_online') }}
              </div>
            </template>
            <div>
              {{ $t('message.what_about_online2') }}
            </div>
          </el-collapse-item>
          <el-collapse-item name="7">
            <template #title>
              <div class="alignleft">
                {{ $t('message.what_about_food') }}
              </div>
            </template>
            <div>
              {{ $t('message.what_about_food2') }}
            </div>
          </el-collapse-item>
          <el-collapse-item name="8">
            <template #title>
              <div class="alignleft">
                {{ $t('message.my_question_isnt_here') }}
              </div>
            </template>
            <div v-html="$t('message.my_question_isnt_here2')"/>
          </el-collapse-item>
        </el-collapse>
      </div>

      <div v-if="sidemenuIndex == '1'">{{ $t('message.policies') }}</div>
      <div v-if="sidemenuIndex == '2'">
        {{ $t('message.contacts') }}
        <button @click="sendMail">{{ $t('message.mail') }}</button>
      </div>
    </div>
  </div>
</template>
  
<script lang="ts">
import EvontNavbar from "../components/evontNavbar.vue";
import { defineComponent, ref } from "vue";

const activeName = ref("1");
export default defineComponent({
  name: "AboutView",
  components: { EvontNavbar },
  data() {
    return {
      sidemenuIndex: "0",
      collapseIndex: "0",
    };
  },
  methods: {
    handleOpen(key: string) {
      this.sidemenuIndex = key.toString();
    },

    goToHome() {
      this.$router.push("/");
    },

    sendMail() {
      const recipient = "example@example.com";
      const subject = "Hello";
      const body = "This is the body of the email.";

      const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(body)}`;

      window.location.href = mailtoLink;
    },
  },

  computed: {},
});
</script>

<style scoped>
.about {
  padding: 100px 0px 60px 0px;
  height: 100%;
  min-height: 100vh;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  /* width: 200px; */
  width: 25vw;
  max-width: 200px;
  height: 100vh;
  top: 0px;
  position: fixed;
}

.cardBodyContainer {
  display: flex !important;
  gap: 30px;
  max-height: 50vh;
  width: 100%;
  flex-direction: row;
}
.cardBodyContainer img {
  max-width: 40%;
  object-fit: scale-down;
}

.el-menu {
  /* background-color: #e1e1e1 !important; */
  background-color: #0e0e0e !important;
}
.is-active {
  border-right: 2px solid var(--el-menu-active-color);
  background-color: #49030c;
}

.navlogo {
  cursor: pointer;
  max-width: 80%;
  padding-left: 8px;
  min-height: var(--el-menu-horizontal-height);
}

.el-collapse {
  --el-collapse-border-color: var(--el-border-color-lighter);
  --el-collapse-header-height: auto;
  --el-collapse-header-bg-color: var(--el-fill-color-blank);
  --el-collapse-header-text-color: var(--el-text-color-primary);
  --el-collapse-header-font-size: 24px;
  --el-collapse-content-bg-color: var(--el-fill-color-blank);
  --el-collapse-content-font-size: 18px;
  --el-collapse-content-text-color: var(--el-text-color-primary);
  border-bottom: none;
  border-top: none;

  width: 90% !important;
}

.el-collapse-item {
  padding-left: 20px;
  border-radius: 3px;
  height: unset !important;
}

.el-collapse-item div {
  margin: 10px !important;
}
.el-collapse-item button {
  margin: 10px !important;
}

.el-collapse-content {
  margin-left: 50px !important;
  color: red;
}

.aboutContent {
  flex: 1;
  padding-left: 210px;
}

.googlemaps{
  width: 850px;
  height: 500px;
  border-radius: 3px
}
.imgmaps{
  width: 550px; 
  height: 500px;
}

a {
  color: var(--el-menu-active-color);
}





@media only screen and (max-width: 600px) {
  .aboutContent {
    padding-left: 0vw;
  }

  .googlemaps{
  width: 90%;
  height: 90%;
  border-radius: 3px
  }

  .imgmaps{
  width: 90%; 
  height: 90%;
  }

  .el-collapse {
    width: 99% !important;
    /* height: 10vh; */
  }

  .el-collapse-item{
    height: unset !important;
  }

  .el-collapse-item button{
    height: unset !important;
  }

  .desktopOnly{
    display: none;
  }

  .alignleft{
    text-align: left;
  }
}
</style>
  