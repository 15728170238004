import { collection, getDocs, getDoc, doc, query, where } from "firebase/firestore";
import { storage, firestore, getDownloadURL, ref } from "./firebase";

async function fetchTournaments() {
    const tournamentsCollection = collection(firestore, 'tournaments');
    const tournamentsSnapshot = await getDocs(tournamentsCollection);
    const tournamentsList = [];

    for (const doc of tournamentsSnapshot.docs) {
        const tournamentData = doc.data();
        console.log(tournamentData);
        console.log((await getDoc(tournamentData.game)).data());
        
        const resultsUrl = tournamentData.results_graphic != null ? getDownloadURL(ref(storage, tournamentData.results_graphic)) : null;
        tournamentsList.push({
            ...tournamentData,
            resultsUrl
        })
    }

    return tournamentsList;
}



//TODO fetch by game
// https://stackoverflow.com/questions/53140913/querying-by-a-field-with-type-reference-in-firestore

async function fetchTournamentsByGame(gameRef:string) {
    const gameDocRef = doc(firestore, "games", gameRef);

    const q = query(
        collection(firestore, 'tournaments'),
        where("game", "==", gameDocRef)
    );

    const docs = await getDocs(q);

    const tournamentsList = [];
    for (const doc of docs.docs) {
        const tournamentData = doc.data();
        
        const resultsUrl = tournamentData.results_graphic != null ? await getDownloadURL(ref(storage, tournamentData.results_graphic)) : null;
        tournamentsList.push({
            ...tournamentData,
            resultsUrl
        })
    }

    return tournamentsList;
}

async function fetchUpcomingTournaments() {

    const q = query(
        collection(firestore, 'tournaments'),
        where('datetime', '>', new Date())
    )

    const docs = await getDocs(q);

    const tournamentsList = [];
    for (const doc of docs.docs) {
        const tournamentData = doc.data();
        
        const resultsUrl = tournamentData.results_graphic != null ? await getDownloadURL(ref(storage, tournamentData.results_graphic)) : null;
        tournamentsList.push({
            ...tournamentData,
            resultsUrl
        })
    }

    return tournamentsList;
}

export { fetchTournaments, fetchTournamentsByGame, fetchUpcomingTournaments };