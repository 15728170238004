import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { storage, firestore, getDownloadURL, ref } from './firebase';

async function fetchGames() {

  const q = query(
    collection(firestore, 'games'),
    orderBy('order')
)
  const gamesCollection = collection(firestore, 'games');
  const gamesSnapshot = await getDocs(q);
  const gamesList = [];

  for (const doc of gamesSnapshot.docs) {
    const gameData = doc.data();
    const logoUrl = await getDownloadURL(ref(storage, gameData.logo));
    const logoSquareUrl = await getDownloadURL(ref(storage, gameData.logo_square));
    const docid = doc.id;
    gamesList.push({
      ...gameData,
      logoUrl,
      logoSquareUrl,
      docid
    });
  }

  return gamesList;
}

export { fetchGames };