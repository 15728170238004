<template>
  <Transition>
    <el-menu
      v-if="isvisible"
      :default-active="activeIndex"
      class="el-menu-demo navbar hardIron"
      mode="horizontal"
      :ellipsis="false"
      :router="true"
    >
      <!-- <img style="max-height: 90%" src="./../assets/Evont.svg" alt="Evont logo" /> -->
      <img
        @click="goToHome()"
        class="navlogo"
        src="./../assets/Evont-4.svg"
        alt="Evont logo"
      />

      <div class="flex-grow" />
      <el-menu-item
        v-if="desktopMode"
        index="/tournaments"
        route="/tournaments"
      >
        <RouterLink to="/">{{ $t('message.tournaments') }}</RouterLink>
      </el-menu-item>

      <el-menu-item v-if="desktopMode" index="/about-us" route="/about-us">
        <RouterLink to="/about-us">{{ $t('message.about_us') }}</RouterLink>
      </el-menu-item>

      <div v-if="!desktopMode" class="mobileIconContainer" @click="toggleSideMenu()">
        <font-awesome-icon :icon="['fas', 'bars']" />
      </div>
    </el-menu>
  </Transition>
  
  <evontSideBar @closeSideBar="toggleSideMenu()" :showSideMenu="showSideMenu" :location="activeIndex"></evontSideBar>
</template>

<script setup>
import evontSideBar from './evontSideBar.vue';
import { ref, onMounted, defineProps } from "vue";
import { useRouter } from "vue-router";

const props = defineProps(["location"]);

const router = useRouter();

const desktopMode = ref(true);
const isvisible = ref(true);
var lastY = 0;
const activeIndex = ref(props.location);

function goToHome() {
  router.push("/");
}

window.onscroll = function () {
  //hide navbar until scroll
  isvisible.value == true;

  if (window.scrollY > lastY) {
    isvisible.value = false;
  } else {
    isvisible.value = true;
  }
  lastY = this.scrollY;
};

function isMobile() {
   if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
     return true
   } else {
     return false
   }
}

const showSideMenu = ref(false)
function toggleSideMenu(){
  showSideMenu.value = !showSideMenu.value;
}

onMounted(() => {
  //check if mobile or desktop
  desktopMode.value = !isMobile(); 
});
</script>

<style scoped>
.navbar {
  position: fixed !important;
  width: 100%;
  max-width: 100vw;
  top: 0;
  z-index: 97;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
}

.navlogo {
  cursor: pointer;
  max-height: 90%;
  padding: 8px;
}

.flex-grow {
  flex-grow: 1;
}

li {
  padding: 0 0 !important;
}

a {
  text-decoration: none;
  height: 100%;
  width: 100%;
  padding: 0 20px;
}

.el-menu {
  /* background-color: #e1e1e1 !important; */
  background-color: #0e0e0e;
  color: white;
}

.el-menu--horizontal.el-menu {
  /* border-bottom: 1px solid #c7c7c7 !important; */
  border-bottom: 1px solid #0a0808 !important;
}

/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.mobileIconContainer {
  height: 100%;
  width: 70px;
  display: flex;
  font-size: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--el-menu-active-color);
}
</style>