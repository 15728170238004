<template>
  <div class="tournaments">

    <EvontNavbar location="/tournaments"></EvontNavbar>
    <!-- <img alt="Vue logo" src="../assets/EvontFull.png" /> -->
    <Loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" color="var(--el-menu-active-color)"
      background-color="var(--bg-color)" />
    <div>
      <el-menu :default-active="sidemenuIndex" class="el-menu-vertical-demo" @select="handleOpen">
        <img @click="goToHome()" class="navlogo" src="./../assets/Evont-4.svg" alt="Evont logo" />
        <el-menu-item v-for="game in games" :index="game.docid || '0'" :key="game.docid || '0'">
          <template #title>
            <!-- {{ game.name }} -->
            <img style="width: 100%" height="56px" class="desktopOnly" :src="game.logoUrl" />

            <img style="width: 100%" class="mobileOnly" :src="game.logoSquareUrl" />
          </template>
        </el-menu-item>
      </el-menu>
    </div>

    <div class="aboutContent">
      <!-- <img style="height:50px;" :src="selectedGame.logo" /> -->

      <el-timeline>
        <el-timeline-item placement="top" v-for="(activity, index) in tournaments" :key="index.toString"
          :timestamp="activity.timestamp">
          <el-card>
            <div class="cardBodyContainer">
              <img :src="activity.resultsUrl" @click="showImageModal(activity.resultsUrl)" style="cursor: pointer;" />

              <div style="flex: 1" class="TourneyDetails">
                <h2>
                  <span class="red">{{ activity.name }}</span> -
                  {{ activity.type }}
                </h2>
                <div>
                  <span class="red">{{ $t('message.format') }}</span> - {{ activity.format }}
                </div>
                <div>
                  <span class="red">{{ isUpcoming(activity.datetime) ? $t('message.start_time') : $t('message.tournament_time')}}</span>:
                  {{ activity.datetime ? printDate(activity.datetime) : "N/A" }}
                </div>
                <div>
                  <span class="red">{{ isUpcoming(activity.datetime) ? $t('message.register') : $t('message.bracket')}}</span>:
                  <a target="_blank" :href="activity.register_url">{{ isUpcoming(activity.datetime) ? $t('message.register_here') : $t('message.bracket_here') }}</a>
                  {{ activity.register_text }}
                </div>
                <div v-if="activity.stream_link != null && activity.stream_link != ''">
                  <span class="red">{{ isUpcoming(activity.datetime) ? $t('message.stream') : $t('message.archive')}}</span>:
                  <a target="_blank" :href="activity.stream_link">{{ $t('message.watch_here') }}</a>
                </div>
                <br />
              </div>

              <div v-if="activity.html != null" class="innerHtmlContainer">
                <span v-html="activity.html"></span>
              </div>
            </div>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </div>
  </div>

  <Teleport to="body">
    <!-- use the modal component, pass in the prop -->
    <image-modal :show="showModal" @close="showModal = false">
      <template #body>
        <img :src="selectedImageModalUrl" class="image_modal_image" />
      </template>
    </image-modal>
  </Teleport>
</template>

<script lang="ts">
import EvontNavbar from "../components/evontNavbar.vue";
import ImageModal from '../components/ImageModal.vue';
import { defineComponent, ref } from "vue";
import { fetchGames } from "../firebase/fetchgames";
import {
  fetchTournaments,
  fetchTournamentsByGame,
  fetchUpcomingTournaments
} from "../firebase/fetchtournaments";
import { DateTime } from "luxon";
import { Timestamp } from "firebase/firestore";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

const games: any = ref([]);

const tournaments: any = ref([]);
const upcomingTournaments: any = ref([]);

export default defineComponent({
  name: "TournamentsView",
  components: { EvontNavbar, Loading, ImageModal },
  data() {
    return {
      sidemenuIndex: "0",
      games: games,
      tournaments: tournaments,
      upcomingTournaments: upcomingTournaments,
      isLoading: false,
      showModal: ref(false),
      selectedImageModalUrl: ''
    };
  },
  async created() {
    this.isLoading = true;
    this.games = [{
      name: 'Upcoming',
      docid: 'upcoming',
      logoUrl: require('@/assets/gameLogos/upcoming-01.png'),
      logoSquareUrl: 'https://firebasestorage.googleapis.com/v0/b/evont-26ddb.appspot.com/o/xfe47_Football-06-512.png?alt=media&token=655c0f95-a3f2-46f2-b34c-303804091c22'
    },
    ...await fetchGames()];
    this.upcomingTournaments = await fetchUpcomingTournaments();
    this.handleOpen("upcoming");
    this.isLoading = false;
  },
  methods: {
    handleOpen(key: string) {
      this.sidemenuIndex = key.toString();
      if (key == "upcoming") {
        this.tournaments = this.upcomingTournaments;
        return;
      }
      this.handleFetchTournaments(key);
    },

    goToHome() {
      this.$router.push("/");
    },

    async handleFetchTournaments(gameid: string) {
      this.isLoading = true;
      const gameTournaments = await fetchTournamentsByGame(gameid);
      this.tournaments = gameTournaments;
      this.isLoading = false;
    },

    printDate(datetime: Timestamp) {
      return DateTime.fromSeconds(datetime.seconds).toLocaleString(
        DateTime.DATETIME_FULL
      );
    },
    showImageModal(imageUrl: string) {
      this.selectedImageModalUrl = imageUrl;
      this.showModal = true;
    },
    isUpcoming(datetime: Timestamp) {
      return datetime.toDate().valueOf() >  Date.now()
    }
  },

  computed: {
    selectedGame(): any {
      if (this.sidemenuIndex == "0") return this.games[0];
      const selectedGame = this.games.find(
        (x: { docid: string }) => x.docid === this.sidemenuIndex
      );
      return selectedGame;
    },
  },
});
</script>

<style scoped>
.tournaments {
  padding: 100px 0px 60px 0px;
  /* display: flex; */
  /* gap: 60px; */
  height: 100%;
  /* flex-direction: column; */
  /* background-color: #f6f6f6; */
  /* background-color: #bfbfbf; */
  /* background-color: var(--bg-color); */
  min-height: 100vh;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 25vw;
  max-width: 200px;
  height: 200vh;
  top: 0px;
  position: fixed;
}

.el-card {
  width: 90% !important;
  /* height: 50%; */
  display: flex;
  gap: 10px !important;
  flex-direction: row;
  align-items: center;
}

.cardBodyContainer {
  display: flex !important;
  gap: 30px;
  max-height: 50vh;
  width: 100%;
  flex-direction: row;
}

.cardBodyContainer img {
  max-width: 40%;
  object-fit: scale-down;
}

.el-menu {
  /* background-color: #e1e1e1 !important; */
  background-color: #0e0e0e !important;
}

.is-active {
  border-right: 2px solid var(--el-menu-active-color);
  background-color: #49030c;
}

.el-timeline-item__timestamp.is-top {
  color: red !important;
}

.navlogo {
  cursor: pointer;
  max-width: 80%;
  padding-left: 8px;
  min-height: var(--el-menu-horizontal-height);
}

.innerHtmlContainer {
  flex: 1 1 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.aboutContent {
  flex: 1;
  padding-left: 210px;
}

.TourneyDetails div {
  padding: 5px;
  font-size: 18px;
}

a {
  color: var(--el-menu-active-color);
}

.red {
  color: var(--el-menu-active-color);
}

.image_modal_image {
  max-width: 90vw;
  max-height: 90vh;
}

@media only screen and (max-width: 600px) {
  .aboutContent {
    padding-left: 16vw;
  }

  .cardBodyContainer {
    flex-direction: column;
  }

  .el-timeline {
    padding-left: 5px !important;
  }

  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 15vw;
    height: 100vh;
    top: 0px;
    position: fixed;
  }
}
</style>